import React from "react";
import { css } from "@emotion/core";
import { Desktop } from "../../constants/responsive";

const Quote = ({
  tTop = "auto",
  tBottom = "auto",
  tLeft = "auto",
  tRight = "auto",
  dTop = "auto",
  dBottom = "auto",
  dLeft = "auto",
  dRight = "auto",
}) => (
  <div
    css={css`
      width: 6px;
      height: 22px;
      border-radius: 10px;
      background-color: #ee2f79;
      transform: rotate(20deg);
      position: absolute;
      top: ${tTop};
      bottom: ${tBottom};
      left: ${tLeft};
      right: ${tRight};
      ${Desktop} {
        width: 10px;
        height: 30px;
        border-radius: 10px;
        top: ${dTop};
        bottom: ${dBottom};
        left: ${dLeft};
        right: ${dRight};
      }
    `}
  ></div>
);

const BannerQuote = ({ children, ...rest }) => {
  return (
    <h2
      css={css`
        position: relative;
      `}
      {...rest}
    >
      <Quote dTop="-10px" dLeft="-15px" tTop="-10px" tLeft="-10px" />
      <Quote dTop="-10px" dLeft="-30px" tTop="-10px" tLeft="-20px" />
      {children}
      <span
        css={css`
          position: relative;
        `}
      >
        <Quote dBottom="-10px" dRight="-15px" tBottom="-4px" tRight="-10px" />
        <Quote dBottom="-10px" dRight="-30px" tBottom="-4px" tRight="-20px" />
      </span>
    </h2>
  );
};

export default BannerQuote;
